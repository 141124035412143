var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-dialog',{staticClass:"feed-add-dialog",attrs:{"title":((_vm.actionToPerform === 'edit' ? _vm.$t('Comn_edit') : _vm.$t('Comn_create')) + " " + (_vm.$t(
      'Stock_stock'
    ))),"top":"25vh","width":"80%","visible":_vm.dialogVisible,"close-on-click-modal":false,"custom-class":"feed-add-dialog-box"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"beforeClose":function($event){return _vm.handleCloseDialog('close')},"open":_vm.handleDialogOpen}},[_c('el-row',[_c('el-row',{staticStyle:{"margin-bottom":"10px"}},[_c('el-date-picker',{attrs:{"clearable":false,"size":"mini","type":"date","format":_vm.upm__getFormatDateString,"value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions},on:{"change":_vm.handelDate},model:{value:(_vm.dateOfPurchase),callback:function ($$v) {_vm.dateOfPurchase=$$v},expression:"dateOfPurchase"}})],1),_vm._v(" "),_c('el-row',[_c('er-data-tables',{attrs:{"type":"white-header-table","table-data":_vm.tableDataArr,"el-table-props":_vm.tableProps,"columns":_vm.getColumns(_vm.tableColumns),"actionColumn":false,"layout":"table","uniqueId":"feed_Add_Dialog"},scopedSlots:_vm._u([{key:"feed_name",fn:function(ref){
    var row = ref.row;
return [_c('er-select',{attrs:{"size":"mini","placeholder":_vm.$t('select_feed'),"disabled":_vm.actionToPerform === 'edit' ? true : false},on:{"change":function($event){return _vm.handleChangeFeedName(row.data)}},model:{value:(row.data.resource_id),callback:function ($$v) {_vm.$set(row.data, "resource_id", $$v)},expression:"row.data.resource_id"}},_vm._l((_vm.getFeedObjResources),function(resource,resource_id){return _c('el-option',{key:resource_id,attrs:{"label":((resource.name) + "-" + (resource.feed_type)),"value":resource_id}})}),1)]}},{key:"number_of_units",fn:function(ref){
    var row = ref.row;
return [_c('el-input-number',{attrs:{"size":"small","min":1,"controls":false},on:{"change":function($event){return _vm.handleChangeInNoOfUnits(row.data)}},model:{value:(row.data.number_of_units),callback:function ($$v) {_vm.$set(row.data, "number_of_units", $$v)},expression:"row.data.number_of_units"}})]}},{key:"unit_quantity",fn:function(ref){
    var row = ref.row;
return [_c('el-input-number',{attrs:{"size":"small","min":1,"controls":false},on:{"change":function($event){return _vm.handleChangeInQuantity(row.data)}},model:{value:(row.data.unit_quantity),callback:function ($$v) {_vm.$set(row.data, "unit_quantity", $$v)},expression:"row.data.unit_quantity"}})]}},{key:"quantity_purchased",fn:function(ref){
    var row = ref.row;
return [_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.quantity_purchased, 2)))]}},{key:"units",fn:function(ref){
    var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.getUnits(row.data.units))+"\n        ")]}},{key:"vendor_id",fn:function(ref){
    var row = ref.row;
return [_c('er-select',{attrs:{"size":"mini","placeholder":_vm.$t('Resource_select_vendors')},model:{value:(row.data.vendor_id),callback:function ($$v) {_vm.$set(row.data, "vendor_id", $$v)},expression:"row.data.vendor_id"}},_vm._l((_vm.getObjVendors),function(vendor){return _c('el-option',{key:vendor._id,attrs:{"label":vendor.name,"value":vendor._id}})}),1)]}},{key:"actions",fn:function(ref){
    var row = ref.row;
return [(row.$index === _vm.tableDataLength - 1)?[_c('er-button',{attrs:{"icon":"el-icon-plus","size":"mini","circle":""},on:{"click":function($event){return _vm.handleAddRow()}}})]:[_c('er-button',{attrs:{"icon":"el-icon-minus","size":"mini","circle":""},on:{"click":function($event){return _vm.handleDeleteRow(row.$index)}}})]]}}])})],1)],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('div',[(_vm.actionToPerform === 'add')?_c('p',{staticClass:"footer__content"},[_vm._v("\n        "+_vm._s(_vm.$t("Stock_total_stock_abbr"))+"\n      ")]):_vm._e()]),_vm._v(" "),_c('div',{staticStyle:{"display":"flex"}},[_c('er-button',{attrs:{"size":"mini","btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.loading},on:{"click":_vm.handleSaveStock}}),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","btnType":"cancel","showLabel":true},on:{"click":function($event){return _vm.handleCloseDialog('close')}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }